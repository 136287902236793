import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { ApiCallsService } from "@app/routes/dashboard/pages/dashboard/api-call-service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Router } from "@angular/router";

@Component({
  selector: "app-livealerts",
  templateUrl: "./livealerts.component.html",
  styleUrls: ["./livealerts.component.css"],

})
export class LivealertsComponent implements OnInit {
  Lastcolor: any;
  Datacolor: any;
  LastTime: any;
  HMIIMageScreen: any;
  selectedPlant = "TCII";
  userForm: FormGroup;
  edituserForm: FormGroup;
  public count = 0;
  allUsers: any;
  Message: any;
  selectedType: any;
  Isplanttype: boolean = false;
  IsMessage: boolean = false;
  selectedUserToUpdate: any;
  tabdata4: any;
  tabVal: any;
  tabdata3: any;
  DeleteUser: any;
  deleteUsername: any;
  
  ErrorHandler: any;
  IsdelerrMessage: boolean = false;
  IsUpderrMessage: boolean = false;
  IsAdderrMessage: boolean = false;
  UpdErrorHandler: any;
  AddErrorHandler: any;
  Formdata: any;
  Firstname: any;
  Lastname: any;
  constructor(
    public dialogRef: MatDialogRef<LivealertsComponent>,
    readonly apiCallsService: ApiCallsService,
    public dialog: MatDialog,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.clicked == 1) {
      this.createUSERForm();
    } else if (this.data.clicked == 2) {
      this.editUserForm();
      this.tabdata4 = this.data.TableData4;
      this.tabVal = this.data.tableval;
      this.selectedUserToUpdate = this.data.UserData;
    } else if (this.data.clicked == 3) {
      this.DeleteUser = this.data.deleteId;
      this.deleteUsername = this.data.deleteUsername;
      this.Firstname =  this.data.Firstname;
      this.Lastname =  this.data.Lastname;
    }
  }
  closed() {
    this.dialogRef.close();
  }
  reloadPage() {
    window.location.reload();
  }
  changePlanttype(types: any) {
    this.selectedType = [];
    this.selectedType = types.target.value;
    if (this.selectedType == "Operator") {
      this.Isplanttype = true;
    } else {
      this.Isplanttype = false;
    }
  }


  // Create Userdetails Api
  AddUserDetails() {
    let body = this.userForm["value"];
    this.apiCallsService.AddUser(body).subscribe(
      (res) => {
        this.Message = res;
        setTimeout(() => {
          if (this.Message.length != 0) {
            this.IsMessage = true;
          } else {
            this.IsMessage = false;
          }
        }, 100);
        setTimeout(() => {
          this.refreshUsersList();
          this.closed();
        }, 1000);
      },
      (err) => {
        this.AddErrorHandler = err.error.message;
        setTimeout(() => {
          if (this.AddErrorHandler.length != 0) {
            this.IsAdderrMessage = true;
          } else {
            this.IsAdderrMessage = false;
          }
        }, 100);
        setTimeout(() => {
          this.closed();
        }, 1000);``
      }
    );
  }

  // RefreshUserList
  refreshUsersList() {
    this.apiCallsService.getAllUsers().subscribe((data) => {
      this.allUsers = data;
    });
  }

  // Update Userdetails Api
  UpdateUserDetails() {
    // let body = this.userForm["value"];
    this.Formdata = this.tabVal;
    if(this.tabVal.role != 'Operator'){
        this.tabVal.plant_type = 'All';
        this.Formdata = this.tabVal;
    }
    let body = this.Formdata;
    this.apiCallsService.UpdateUser(body).subscribe(
      (res) => {
        this.Message = res;
        setTimeout(() => {
          if (this.Message.length != 0) {
            this.IsMessage = true;
          } else {
            this.IsMessage = false;
          }
        }, 100);
        setTimeout(() => {
          this.closed();
        }, 1000);
      },
      (err) => {
        this.UpdErrorHandler = err.error.message;
        setTimeout(() => {
          if (this.UpdErrorHandler.length != 0) {
            this.IsUpderrMessage = true;
          } else {
            this.IsUpderrMessage = false;
          }
        }, 500);
        setTimeout(() => {
          this.closed();
        }, 3000);
     
      }
    );
  }

  // Delete Userdetails Api
  DeleteUserDetails() {
    this.apiCallsService.DeleteUserId(this.DeleteUser).subscribe(
      (res) => {
        this.Message = res;
        setTimeout(() => {
          if (this.Message.length != 0) {
            this.IsMessage = true;
          } else {
            this.IsMessage = false;
          }
        }, 100);
        setTimeout(() => {
          this.closed();
        }, 1000);
      },
      (err) => {
        this.ErrorHandler = err.error.message;
        setTimeout(() => {
          if (this.ErrorHandler.length != 0) {
            this.IsdelerrMessage = true;
          } else {
            this.IsdelerrMessage = false;
          }
        }, 500);
        setTimeout(() => {
          this.closed();
        }, 3000);
      
      }
    );
  }

  // Add UserForm
  private createUSERForm() {
    this.userForm = new FormGroup({
      first_name: new FormControl("", Validators.required),
      last_name: new FormControl("", Validators.required),
      username: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        (Validators.required,
        Validators.pattern("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"))
      ),
      password1: new FormControl("", Validators.required),
      password2: new FormControl("", Validators.required),
      plant_type: new FormControl(""),
      role: new FormControl("", Validators.required),
    
    });
  }

  // edituserForm
  private editUserForm() {
    this.edituserForm = new FormGroup({
      first_name: new FormControl("", Validators.required),
      last_name: new FormControl("", Validators.required),
      username: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        (Validators.required,
        Validators.pattern("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"))
      ),
      password1: new FormControl("", Validators.required),
      password2: new FormControl("", Validators.required),
       plant_type: new FormControl(""),
      role: new FormControl("", Validators.required),
    });
  }
}
