import { Component, Inject, OnInit, OnDestroy, AfterViewInit } from '@angular/core';                                                      
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly"
import { ApiCallService2 } from '@app/routes/dashboard/pages/Real-time-perfomance/api-call-service';
import { setTheme } from 'ngx-bootstrap/utils';
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment"
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
const timeZoneUS = environment.timeZone;



@Component({
  selector: 'app-popup1',
  templateUrl: './popup1.component.html',
  styleUrls: ['./popup1.component.css']
})
export class Popup1Component implements OnInit, OnDestroy {
  maxDate = new Date();
  TokenHandler: any;
  myDateValue: any;
  type: any;
  dateRangaPicker: any;
  private chart1: am4charts.XYChart;
  private chart2: am4charts.XYChart;
  record_time: string[] = [];
  finalAggtemp: number[] = [];
  finalAggmois: number[] = [];
  analysis: any;
  temp: any;
  time: any;
  production: any;
  isLoading: any;
  historyData: any;
  appliedDateFilters: any = {};
  KPI1: any;
  KPI2: any;
  startDate: any;
  endDate: any;
  StatusType: any;
  SelectedTemp: any;
  SelectedHumidity: any;
  previousType = "MH";

  private previousDateValue: Date;
  imageData: any;
  imageSrc: string;
  image: SafeUrl;
  gas_data: any;
  constructor(
    public dialogRef1: MatDialogRef<Popup1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCallService: ApiCallService2,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }
  cosntructor() {
    setTheme('bs3');
  }
  ngOnInit() {
    this.isLoading = true;
    this.imageSrc = null;
    this.myDateValue = new Date();
    this.previousDateValue = this.myDateValue;
    this.SelectedHumidity = true;
    this.SelectedTemp = false;
    this.getUnifiedData();
  }

  ngOnDestroy() {
  }
  private datesAreEqual(date1: Date, date2: Date): boolean {
    return date1 && date2 && date1.getTime() === date2.getTime();
  }
  changeDropdownGraph(types: any) {
    if (this.previousType != types.target.value) {
      this.StatusType = types.target.value;
      this.isLoading = true;
      if (this.StatusType == "MH") {
        this.SelectedTemp = false;
        this.SelectedHumidity = true;
        this.getUnifiedData();
      } else if (this.StatusType == "M") {
        this.SelectedTemp = false;
        this.SelectedHumidity = false;
        this.getUnifiedData();
      } else if (this.StatusType == "MA") {
        this.SelectedTemp = true;
        this.SelectedHumidity = false;
        this.getUnifiedData();
      }
      else if (this.StatusType == "MHA") {
        this.SelectedTemp = true;
        this.SelectedHumidity = true;
        this.getUnifiedData();
      }
      this.previousType = this.StatusType;
    }
  }
  onDateChange(newValue: Date): void {
    if (!this.datesAreEqual(newValue, this.previousDateValue)) {
      this.isLoading = true;
      this.getUnifiedData();
      console.log('date', this.startDate);
      this.previousDateValue = newValue;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["auth"]);
  }
  timeStampChange(plantData: any, epochAdd: any) {
    plantData.forEach(item => {
      item.record_time_epoch = ((item.record_time / 1000) - epochAdd) * 1000;
    });
  }
  getUnifiedData() {
    if(this.data == 1){
      this.type = 'moisture'
      this.getMoistureData();
    }
    if(this.data == 2){
      this.type = 'temp'
      this.getTempData();
    }
    if(this.data == 3){
      this.type = 'prod'
      this.getProdData();
    }
    if(this.data == 4){
      this.type = 'gasflow'
      this.getGasflowData();
    }
    if(this.data == 6){
      this.type = 'gas'
      this.getGasConsumtion();
    }
    if(this.data == 5){this.getImageData();}
  }
  getGasflowData() {
    console.log('myDateValue', this.myDateValue);
    const myDate = new Date(this.myDateValue)
    console.log('myDate', myDate);
    let startEpoch = this.myDateValue.setHours(0, 0, 0, 0);
    let endEpoch = this.myDateValue.setHours(23, 59, 59, 999);
    const offsetValue = new Date(startEpoch).getTimezoneOffset();
    const month = new Date(this.myDateValue).getMonth();
    const offsetValueToUse = (month >= 2 && month <= 9) ? 300 : 360;
    const offsetDiff = (offsetValueToUse - offsetValue) / 30;

    const epochAdd = offsetDiff * 1800;

    startEpoch = ((startEpoch / 1000) + epochAdd) * 1000;
    endEpoch = ((endEpoch / 1000) + epochAdd) * 1000;
    this.startDate = startEpoch;
    this.endDate = endEpoch;
    console.log('startDate: ', this.startDate)
    this.apiCallService
      .historyPlantData(
        this.startDate,
        this.endDate,
        this.type
      )
      .subscribe(
        (res) => {
          this.historyData = res;
          this.KPI1 = this.historyData.KPI_1;   
          setTimeout(() => {
              this.KPI1.forEach(obj => {
                obj.value = 1;
              });
  
              this.KPI1.forEach(item => {
                item.record_time_epoch = ((item.record_time_epoch / 1000) - epochAdd) * 1000;
                item.gas_flow_rate = (item.gas_flow_rate/100).toFixed(2);
                item.gas_flow_rate_actual = (item.gas_flow_rate*(1-(360-item.drum_temp)/100*(1-item.moisture_per/100))).toFixed(2)
              });
              this.drawLineChart4();
          }, 1000);
          setTimeout(() => {
            this.isLoading = false;
          }, 900)
          // this.isLoading=false;

        },
        (err) => {
          console.log(err);
          this.TokenHandler = err.error.message;
          console.log(this.TokenHandler);
          if (this.TokenHandler == "Token is invalid") {
            this.logout();
          }
        }
      );
  }
  getTempData() {
    console.log('myDateValue', this.myDateValue);
    const myDate = new Date(this.myDateValue)
    console.log('myDate', myDate);
    let startEpoch = this.myDateValue.setHours(0, 0, 0, 0);
    let endEpoch = this.myDateValue.setHours(23, 59, 59, 999);
    const offsetValue = new Date(startEpoch).getTimezoneOffset();
    const month = new Date(this.myDateValue).getMonth();
    const offsetValueToUse = (month >= 2 && month <= 9) ? 300 : 360;
    const offsetDiff = (offsetValueToUse - offsetValue) / 30;

    const epochAdd = offsetDiff * 1800;

    startEpoch = ((startEpoch / 1000) + epochAdd) * 1000;
    endEpoch = ((endEpoch / 1000) + epochAdd) * 1000;
    this.startDate = startEpoch;
    this.endDate = endEpoch;
    console.log('startDate: ', this.startDate)
    this.apiCallService
      .historyPlantData(
        this.startDate,
        this.endDate,
        this.type
      )
      .subscribe(
        (res) => {
          this.historyData = res;
            this.KPI1 = this.historyData.KPI_1;    
          setTimeout(() => {
            
              this.KPI1.forEach(obj => {
                obj.value = 1;
              });
  
              this.KPI1.forEach(item => {
                item.record_time_epoch = ((item.record_time_epoch / 1000) - epochAdd) * 1000;
              });
              this.drawLineChart2();
          }, 1000);
          setTimeout(() => {
            this.isLoading = false;
          }, 900)
          // this.isLoading=false;

        },
        (err) => {
          console.log(err);
          this.TokenHandler = err.error.message;
          console.log(this.TokenHandler);
          if (this.TokenHandler == "Token is invalid") {
            this.logout();
          }
        }
      );
  }
  getProdData() {
    console.log('myDateValue', this.myDateValue);
    const myDate = new Date(this.myDateValue)
    console.log('myDate', myDate);
    let startEpoch = this.myDateValue.setHours(0, 0, 0, 0);
    let endEpoch = this.myDateValue.setHours(23, 59, 59, 999);
    const offsetValue = new Date(startEpoch).getTimezoneOffset();
    const month = new Date(this.myDateValue).getMonth();
    const offsetValueToUse = (month >= 2 && month <= 9) ? 300 : 360;
    const offsetDiff = (offsetValueToUse - offsetValue) / 30;

    const epochAdd = offsetDiff * 1800;

    startEpoch = ((startEpoch / 1000) + epochAdd) * 1000;
    endEpoch = ((endEpoch / 1000) + epochAdd) * 1000;
    this.startDate = startEpoch;
    this.endDate = endEpoch;
    console.log('startDate: ', this.startDate)
    this.apiCallService
      .historyPlantData(
        this.startDate,
        this.endDate,
        this.type
      )
      .subscribe(
        (res) => {
          this.historyData = res;
            this.KPI2 = this.historyData.KPI_1     
          setTimeout(() => {
              this.KPI2.forEach(item => {
                item.record_time_epoch = ((item.record_time_epoch / 1000) - epochAdd) * 1000;
              });
              this.drawProductionChart();
          }, 1000);
          setTimeout(() => {
            this.isLoading = false;
          }, 900)
          // this.isLoading=false;

        },
        (err) => {
          console.log(err);
          this.TokenHandler = err.error.message;
          console.log(this.TokenHandler);
          if (this.TokenHandler == "Token is invalid") {
            this.logout();
          }
        }
      );
  }
  getMoistureData() {
    console.log('myDateValue', this.myDateValue);
    const myDate = new Date(this.myDateValue)
    console.log('myDate', myDate);
    let startEpoch = this.myDateValue.setHours(0, 0, 0, 0);
    let endEpoch = this.myDateValue.setHours(23, 59, 59, 999);
    const offsetValue = new Date(startEpoch).getTimezoneOffset();
    const month = new Date(this.myDateValue).getMonth();
    const offsetValueToUse = (month >= 2 && month <= 9) ? 300 : 360;
    const offsetDiff = (offsetValueToUse - offsetValue) / 30;

    const epochAdd = offsetDiff * 1800;

    startEpoch = ((startEpoch / 1000) + epochAdd) * 1000;
    endEpoch = ((endEpoch / 1000) + epochAdd) * 1000;
    this.startDate = startEpoch;
    this.endDate = endEpoch;
    console.log('startDate: ', this.startDate)
    this.apiCallService
      .historyPlantData(
        this.startDate,
        this.endDate,
        this.type
      )
      .subscribe(
        (res) => {
          this.historyData = res;
          this.KPI1 = this.historyData.KPI_1;    
          setTimeout(() => {
              this.KPI1.forEach(obj => {
                obj.value = 1;
              });
  
              this.KPI1.forEach(item => {
                item.record_time_epoch = ((item.record_time_epoch / 1000) - epochAdd) * 1000;
              });
              this.drawLineChart1();
          }, 1000);
          setTimeout(() => {
            this.isLoading = false;
          }, 900)
          // this.isLoading=false;

        },
        (err) => {
          console.log(err);
          this.TokenHandler = err.error.message;
          console.log(this.TokenHandler);
          if (this.TokenHandler == "Token is invalid") {
            this.logout();
          }
        }
      );
  }
  getGasConsumtion() {
    console.log('myDateValue', this.myDateValue);
    const myDate = new Date(this.myDateValue)
    console.log('myDate', myDate);
    let startEpoch = this.myDateValue.setHours(0, 0, 0, 0);
    let endEpoch = this.myDateValue.setHours(23, 59, 59, 999);
    const offsetValue = new Date(startEpoch).getTimezoneOffset();
    const month = new Date(this.myDateValue).getMonth();
    const offsetValueToUse = (month >= 2 && month <= 9) ? 300 : 360;
    const offsetDiff = (offsetValueToUse - offsetValue) / 30;

    const epochAdd = offsetDiff * 1800;

    startEpoch = ((startEpoch / 1000) + epochAdd) * 1000;
    endEpoch = ((endEpoch / 1000) + epochAdd) * 1000;
    this.startDate = startEpoch;
    this.endDate = endEpoch;
    console.log('startDate: ', this.startDate)
    this.apiCallService
      .historyPlantData(
        this.startDate,
        this.endDate,
        this.type
      )
      .subscribe(
        (res) => {
          this.historyData =res;
          this.gas_data = this.historyData.KPI_1;
          setTimeout(() => {
            this.gas_data.forEach(item => {
              item.record_time = (item.record_time - epochAdd) * 1000;
            })
              this.drawLineChart5();
          }, 1000);
          setTimeout(() => {
            this.isLoading = false;
          }, 900)
          // this.isLoading=false;

        },
        (err) => {
          console.log(err);
          this.TokenHandler = err.error.message;
          console.log(this.TokenHandler);
          if (this.TokenHandler == "Token is invalid") {
            this.logout();
          }
        }
      );
  }

  getImageData() {
    this.apiCallService
      .getImageData().subscribe(
        (res) => {
          this.imageData = res.Images[0];
          this.imageSrc = `data:image/jpeg;base64,${this.imageData}`
            this.isLoading=false;
        },
        (err) => {
          console.log(err);
          this.TokenHandler = err.error.message;
          console.log(this.TokenHandler);
          if (this.TokenHandler == "Token is invalid") {
            this.logout();
          }
        }
    );
  }
 
  drawLineChart1() {
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    const fontSize = this.getResponsiveFontSize();
    const minGridDistance = this.getResponsiveminGridDistance();
    const chartHeight = this.getResponsiveminChartHeight();
    var chart = am4core.create('lineChart1', am4charts.XYChart);
    chart.logo.disabled = true;
    chart.responsive.enabled = true;
    chart.paddingRight = 15;
    chart.marginRight = 15;
    chart.paddingLeft = 10;
    chart.marginLeft = 10;
    chart.data = this.KPI1;
    chart.height = chartHeight;
    chart.width = am4core.percent(98);
    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.fontWeight = "bold";
    chart.legend.labels.template.fill = am4core.color("#FFFFFF");

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let date = new Date();
    const myDateValue = new Date(this.myDateValue);
    const normalizedMyDateValue = new Date(myDateValue.setHours(0, 0, 0, 0));
    const normalizedCurrentDate = new Date(date.setHours(0, 0, 0, 0));

    if (normalizedMyDateValue.getTime() === normalizedCurrentDate.getTime()) {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
    } else {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
      dateAxis.max = new Date(this.myDateValue).setHours(23, 59, 59, 999);
    }
    dateAxis.renderer.grid.template.location = 0;

    dateAxis.title.fill = am4core.color('#fff');
    // dateAxis.title.fontWeight = 'bold';

    dateAxis.renderer.grid.template.stroke = am4core.color('#fff');
    dateAxis.title.text = "Time";
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.renderer.labels.template.fontSize = 15;
    dateAxis.baseInterval = { timeUnit: 'minute', count: 10 };
    dateAxis.skipEmptyPeriods = false;
    dateAxis.dateFormats.setKey("hour", "hh:mm a");  // Format for hour interval
    dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a"); // Prevent displaying dates
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineY.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if(chart.data && chart.data[0] && chart.data[0].moisture_per === null){
      valueAxis.max = 100;
    }
    // valueAxis.max = 100; 
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.title.text = 'Moisture (%)';
    valueAxis.title.fill = am4core.color('#FF8714');
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fontSize = fontSize;
    valueAxis.renderer.minGridDistance = minGridDistance;
    valueAxis.renderer.labels.template.fill = am4core.color('#FF8714');
    valueAxis.renderer.grid.template.stroke = am4core.color('#fff');
    valueAxis.cursorTooltipEnabled = false;
    if (this.SelectedTemp) {
      var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.min = 0;
      valueAxis1.max = 100;
      valueAxis1.renderer.inside = false;
      valueAxis1.renderer.labels.template.disabled = false;
      if (this.SelectedHumidity === false && this.SelectedTemp === true) {
        valueAxis1.title.text = 'Ambient Temperature (°F)';
        valueAxis1.title.fill = am4core.color('#4CAF50');
        valueAxis1.renderer.labels.template.fill = am4core.color('#4CAF50');
      }
      else {
        valueAxis1.title.text = 'Ambient Temperature (°F) & Humidity (RH, %)';
        valueAxis1.title.fill = am4core.color('#fff');
        valueAxis1.renderer.labels.template.fill = am4core.color('#fff');
      }

      // valueAxis1.title.fontWeight = 'bold';
      valueAxis1.renderer.labels.template.fontSize = fontSize;
      valueAxis1.renderer.minGridDistance = minGridDistance;

      valueAxis1.cursorTooltipEnabled = false;
      valueAxis1.renderer.opposite = true;
    }
    // valueAxis1.hidden =true;
    if (this.SelectedHumidity === true && this.SelectedTemp === false) {
      var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.min = 0;
      valueAxis2.max = 100;
      valueAxis2.renderer.inside = false;
      valueAxis2.renderer.labels.template.disabled = false;
      valueAxis2.title.text = 'Humidity (RH, %)';
      valueAxis2.title.fill = am4core.color('rgb(59, 205, 196)');
      // valueAxis2.title.fontWeight = 'bold';
      valueAxis2.renderer.labels.template.fontSize = 15;
      valueAxis2.renderer.labels.template.fill = am4core.color('rgb(59, 205, 196);');
      valueAxis2.cursorTooltipEnabled = false;
      valueAxis2.renderer.opposite = true;
      // valueAxis2.hidden =true;  
    }
    // Add a dashed horizontal line at y = 1 and label it
    let range = valueAxis.axisRanges.create();
    range.value = 1;
    range.grid.stroke = am4core.color("#FF0000");
    range.grid.strokeWidth = 3;
    range.grid.strokeOpacity = 1;
    range.grid.strokeDasharray = "4,4";

    // let label = chart.createChild(am4core.Label);
    // label.text = "Threshold"; 
    // label.fill = am4core.color('#FF0000'); 
    // label.fontSize = 15; 
    // label.dx = 50; 
    // label.dy = -150; 
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.yAxis = valueAxis;
    series.dataFields.valueY = 'moisture_per';

    series.dataFields.dateX = 'record_time_epoch';
    series.name = 'Moisture';
    series.stroke = am4core.color('#FF8714');
    series.fill = am4core.color('#FF8714');
    series.legendSettings.labelText = "{name}";
    series.tooltipText = '[font-size: 12px]{name}: {valueY}(%)[/]';
    series.strokeWidth = 2;
    series.tensionX = 1;


    if (this.SelectedTemp) {
      var series1 = chart.series.push(new am4charts.LineSeries());
      series1.yAxis = valueAxis1;
      series1.dataFields.valueY = 'ambient_temperature';
      series1.dataFields.dateX = 'record_time_epoch';
      series1.name = 'Ambient Temperature';
      series1.stroke = am4core.color('#4CAF50');
      series1.fill = am4core.color('#4CAF50');
      series1.legendSettings.labelText = "{name}";
      series1.tooltipText = '[font-size: 12px]{name}: {valueY}(°F)[/]';
      series1.strokeWidth = 2;
      series1.tensionX = 1;
      // series1.hidden = true;    
    }
    if (this.SelectedHumidity) {
      var series2 = chart.series.push(new am4charts.LineSeries());
      if (this.SelectedHumidity === true && this.SelectedTemp === false) {
        series2.yAxis = valueAxis2;
      }
      else {
        series2.yAxis = valueAxis1;
      }

      series2.dataFields.valueY = 'relative_humidity';
      series2.dataFields.dateX = 'record_time_epoch';
      series2.name = 'Humidity';
      series2.stroke = am4core.color('rgb(59, 205, 196)');
      series2.fill = am4core.color('rgb(59, 205, 196)');
      series2.legendSettings.labelText = "{name}";
      series2.tooltipText = '[font-size: 12px]{name}: {valueY}(%)[/]';
      series2.strokeWidth = 2;
      series2.tensionX = 1;
      // series2.hidden = true;
    }
    // Add bullets to the series
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#FF8714');
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 0;
    circle.stroke = am4core.color('#FF8714');
    circle.strokeWidth = 2;

    if (this.SelectedTemp) {
      let bullet1 = series1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color('#FF8714');
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 0;
      circle1.stroke = am4core.color('#FF8714');
      circle1.strokeWidth = 2;
    }

    if (this.SelectedHumidity) {
      let bullet2 = series2.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color('#FF8714');
      let circle2 = bullet2.createChild(am4core.Circle);
      circle2.radius = 0;
      circle2.stroke = am4core.color('#FF8714');
      circle2.strokeWidth = 2;
    }
    chart.legend.position = 'bottom';
    var title = chart.titles.create();
    title.fill = am4core.color('#fff');

  }

  drawLineChart2() {
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    const fontSize = this.getResponsiveFontSize();
    const minGridDistance = this.getResponsiveminGridDistance();
    const chartHeight = this.getResponsiveminChartHeight();
    var chart = am4core.create('lineChart2', am4charts.XYChart);
    console.log('fontSize', fontSize);
    chart.logo.disabled = true;
    chart.responsive.enabled = true;
    chart.paddingRight = 15;
    chart.marginRight = 15;
    chart.paddingLeft = 10;
    chart.marginLeft = 10;
    chart.data = this.KPI1;
    chart.height = chartHeight;
    chart.width = am4core.percent(98);
    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.fontWeight = "bold";
    chart.legend.labels.template.fill = am4core.color("#FFFFFF");

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let date = new Date();
    const myDateValue = new Date(this.myDateValue);
    const normalizedMyDateValue = new Date(myDateValue.setHours(0, 0, 0, 0));
    const normalizedCurrentDate = new Date(date.setHours(0, 0, 0, 0));

    if (normalizedMyDateValue.getTime() === normalizedCurrentDate.getTime()) {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
    } else {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
      dateAxis.max = new Date(this.myDateValue).setHours(23, 59, 59, 999);
    }
    dateAxis.renderer.grid.template.location = 0;

    dateAxis.title.fill = am4core.color('#fff');
    // dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.renderer.grid.template.stroke = am4core.color('#fff');
    dateAxis.title.text = "Time";


    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    // dateAxis.renderer.minGridDistance = minGridDistance;
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.baseInterval = { timeUnit: 'minute', count: 10 };
    dateAxis.skipEmptyPeriods = false;
    dateAxis.dateFormats.setKey("hour", "hh:mm a");  // Format for hour interval
    dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a"); // Prevent displaying dates
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineY.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if(chart.data && chart.data[0] && chart.data[0].moisture_per === null){
      valueAxis.max = 100;
    }
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.title.text = 'Asphalt Mix Temperature (°F)';
    valueAxis.title.fill = am4core.color('#FF8714');
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fontSize = fontSize;
    valueAxis.renderer.minGridDistance = minGridDistance;
    valueAxis.renderer.labels.template.fill = am4core.color('#FF8714');
    valueAxis.renderer.grid.template.stroke = am4core.color('#fff');
    valueAxis.cursorTooltipEnabled = false;


    // var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis1.min = 0;
    // valueAxis1.max = 100; 
    // valueAxis1.renderer.inside = false;
    // valueAxis1.renderer.labels.template.disabled = false;
    // valueAxis1.title.text = 'Moisture (%)';
    // valueAxis1.title.fill = am4core.color('#4CAF50');
    // valueAxis1.title.fontWeight = 'bold';
    // valueAxis1.renderer.labels.template.fontSize = fontSize;
    // valueAxis1.renderer.minGridDistance = minGridDistance;
    // valueAxis1.renderer.labels.template.fill = am4core.color('#4CAF50');
    // valueAxis1.cursorTooltipEnabled = false;
    // valueAxis1.renderer.opposite =true;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.yAxis = valueAxis;
    series.dataFields.valueY = 'final_agg_temp_mos';

    series.dataFields.dateX = 'record_time_epoch';
    series.name = 'Asphalt Mix Temperature';
    series.stroke = am4core.color('#FF8714');
    series.fill = am4core.color('#FF8714');
    series.legendSettings.labelText = "{name}";
    series.tooltipText = '[font-size: 12px]{name}: {valueY} (°F)[/]';
    series.strokeWidth = 2;
    series.tensionX = 1;

    var series1 = chart.series.push(new am4charts.LineSeries());
    series1.yAxis = valueAxis;
    series1.strokeDasharray = "5,5";
    series1.dataFields.valueY = 'final_agg_temp_1';
    series1.dataFields.dateX = 'record_time_epoch';
    series1.name = 'Optimized Asphalt Mix Temperature';
    series1.stroke = am4core.color('#FF8714');
    series1.fill = am4core.color('#FF8714');
    series1.legendSettings.labelText = "{name}";
    series1.tooltipText = '[font-size: 12px]{name}: {valueY} (°F)[/]';
    series1.strokeWidth = 2;
    series1.tensionX = 1;

    // var series2 = chart.series.push(new am4charts.LineSeries());
    // series2.yAxis = valueAxis1;
    // series2.dataFields.valueY = 'moisture_per';
    // series2.dataFields.dateX = 'record_time_epoch';
    // series2.name = 'Moisture';
    // series2.stroke = am4core.color('#4CAF50');
    // series2.fill = am4core.color('#4CAF50');
    // series2.legendSettings.labelText = "{name}";
    // series2.tooltipText = '[font-size: 12px]{name}: {valueY} (%)[/]';
    // series2.strokeWidth = 2;
    // series2.tensionX = 0.8;

    // Add bullets to the series
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#FF8714');
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 0;
    circle.stroke = am4core.color('#FF8714');
    circle.strokeWidth = 2;

    let bullet1 = series1.bullets.push(new am4charts.Bullet());
    bullet1.fill = am4core.color('#FF8714');
    let circle1 = bullet1.createChild(am4core.Circle);
    circle1.radius = 0;
    circle1.stroke = am4core.color('#FF8714');
    circle1.strokeWidth = 2;

    // let bullet2 = series2.bullets.push(new am4charts.Bullet());
    // bullet2.fill = am4core.color('#FF8714');
    // let circle2 = bullet2.createChild(am4core.Circle);
    // circle2.radius = 0;
    // circle2.stroke = am4core.color('#FF8714');
    // circle2.strokeWidth = 2;

    chart.legend.position = 'bottom';
    var title = chart.titles.create();
    title.fill = am4core.color('#fff');

  }

  drawLineChart4() {
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    const fontSize = this.getResponsiveFontSize();
    const minGridDistance = this.getResponsiveminGridDistance();
    const chartHeight = this.getResponsiveminChartHeight();
    var chart = am4core.create('lineChart4', am4charts.XYChart);
    console.log('fontSize', fontSize);
    chart.logo.disabled = true;
    chart.responsive.enabled = true;
    chart.paddingRight = 15;
    chart.marginRight = 15;
    chart.paddingLeft = 10;
    chart.marginLeft = 10;
    chart.data = this.KPI1;
    chart.height = chartHeight;
    chart.width = am4core.percent(98);
    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.fontWeight = "bold";
    chart.legend.labels.template.fill = am4core.color("#FFFFFF");

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let date = new Date();
    const myDateValue = new Date(this.myDateValue);
    const normalizedMyDateValue = new Date(myDateValue.setHours(0, 0, 0, 0));
    const normalizedCurrentDate = new Date(date.setHours(0, 0, 0, 0));

    if (normalizedMyDateValue.getTime() === normalizedCurrentDate.getTime()) {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
    } else {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
      dateAxis.max = new Date(this.myDateValue).setHours(23, 59, 59, 999);
    }
    dateAxis.renderer.grid.template.location = 0;

    dateAxis.title.fill = am4core.color('#fff');
    // dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.renderer.grid.template.stroke = am4core.color('#fff');
    dateAxis.title.text = "Time";


    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    // dateAxis.renderer.minGridDistance = minGridDistance;
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.baseInterval = { timeUnit: 'minute', count: 10 };
    dateAxis.skipEmptyPeriods = false;
    dateAxis.dateFormats.setKey("hour", "hh:mm a");  // Format for hour interval
    dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a"); // Prevent displaying dates
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineY.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if(chart.data && chart.data[0] && chart.data[0].moisture_per === null){
      valueAxis.max = 100;
    }
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.title.text = 'Gas Flow Rate (CFM)';
    valueAxis.title.fill = am4core.color('#FF8714');
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fontSize = fontSize;
    valueAxis.renderer.minGridDistance = minGridDistance;
    valueAxis.renderer.labels.template.fill = am4core.color('#FF8714');
    valueAxis.renderer.grid.template.stroke = am4core.color('#fff');
    valueAxis.cursorTooltipEnabled = false;


    // var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis1.min = 0;
    // valueAxis1.max = 100; 
    // valueAxis1.renderer.inside = false;
    // valueAxis1.renderer.labels.template.disabled = false;
    // valueAxis1.title.text = 'Moisture (%)';
    // valueAxis1.title.fill = am4core.color('#4CAF50');
    // valueAxis1.title.fontWeight = 'bold';
    // valueAxis1.renderer.labels.template.fontSize = fontSize;
    // valueAxis1.renderer.minGridDistance = minGridDistance;
    // valueAxis1.renderer.labels.template.fill = am4core.color('#4CAF50');
    // valueAxis1.cursorTooltipEnabled = false;
    // valueAxis1.renderer.opposite =true;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.yAxis = valueAxis;
    series.dataFields.valueY = 'gas_flow_rate';

    series.dataFields.dateX = 'record_time_epoch';
    series.name = 'Actual Gas Flow Rate';
    series.stroke = am4core.color('#FF8714');
    series.fill = am4core.color('#FF8714');
    series.legendSettings.labelText = "{name}";
    series.tooltipText = '[font-size: 12px]{name}: {valueY} (CFM)[/]';
    series.strokeWidth = 2;
    series.tensionX = 1;

    var series1 = chart.series.push(new am4charts.LineSeries());
    series1.yAxis = valueAxis;
    series1.strokeDasharray = "5,5";
    series1.dataFields.valueY = 'gas_flow_rate_actual';
    series1.dataFields.dateX = 'record_time_epoch';
    series1.name = 'Recommended Gas Flow Rate';
    series1.stroke = am4core.color('#FF8714');
    series1.fill = am4core.color('#FF8714');
    series1.legendSettings.labelText = "{name}";
    series1.tooltipText = '[font-size: 12px]{name}: {valueY} (CFM)[/]';
    series1.strokeWidth = 2;
    series1.tensionX = 1;

    // var series2 = chart.series.push(new am4charts.LineSeries());
    // series2.yAxis = valueAxis1;
    // series2.dataFields.valueY = 'moisture_per';
    // series2.dataFields.dateX = 'record_time_epoch';
    // series2.name = 'Moisture';
    // series2.stroke = am4core.color('#4CAF50');
    // series2.fill = am4core.color('#4CAF50');
    // series2.legendSettings.labelText = "{name}";
    // series2.tooltipText = '[font-size: 12px]{name}: {valueY} (%)[/]';
    // series2.strokeWidth = 2;
    // series2.tensionX = 0.8;

    // Add bullets to the series
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#FF8714');
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 0;
    circle.stroke = am4core.color('#FF8714');
    circle.strokeWidth = 2;

    let bullet1 = series1.bullets.push(new am4charts.Bullet());
    bullet1.fill = am4core.color('#FF8714');
    let circle1 = bullet1.createChild(am4core.Circle);
    circle1.radius = 0;
    circle1.stroke = am4core.color('#FF8714');
    circle1.strokeWidth = 2;

    // let bullet2 = series2.bullets.push(new am4charts.Bullet());
    // bullet2.fill = am4core.color('#FF8714');
    // let circle2 = bullet2.createChild(am4core.Circle);
    // circle2.radius = 0;
    // circle2.stroke = am4core.color('#FF8714');
    // circle2.strokeWidth = 2;

    chart.legend.position = 'bottom';
    var title = chart.titles.create();
    title.fill = am4core.color('#fff');

  }

  drawLineChart5() {
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    const fontSize = this.getResponsiveFontSize();
    const minGridDistance = this.getResponsiveminGridDistance();
    const chartHeight = this.getResponsiveminChartHeight();
    var chart = am4core.create('lineChart5', am4charts.XYChart);
    console.log('fontSize', fontSize);
    chart.logo.disabled = true;
    chart.responsive.enabled = true;
    chart.paddingRight = 15;
    chart.marginRight = 15;
    chart.paddingLeft = 10;
    chart.marginLeft = 10;
    chart.data = this.gas_data;
    chart.height = chartHeight;
    chart.width = am4core.percent(98);
    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.fontWeight = "bold";
    chart.legend.labels.template.fill = am4core.color("#FFFFFF");

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let date = new Date();
    const myDateValue = new Date(this.myDateValue);
    const normalizedMyDateValue = new Date(myDateValue.setHours(0, 0, 0, 0));
    const normalizedCurrentDate = new Date(date.setHours(0, 0, 0, 0));

    if (normalizedMyDateValue.getTime() === normalizedCurrentDate.getTime()) {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
    } else {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
      dateAxis.max = new Date(this.myDateValue).setHours(23, 59, 59, 999);
    }
    dateAxis.renderer.grid.template.location = 0;

    dateAxis.title.fill = am4core.color('#fff');
    // dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.renderer.grid.template.stroke = am4core.color('#fff');
    dateAxis.title.text = "Time";


    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    // dateAxis.renderer.minGridDistance = minGridDistance;
    dateAxis.renderer.labels.template.fontSize = fontSize;
    // dateAxis.baseInterval = { timeUnit: 'minute', count: 10 };
    // dateAxis.skipEmptyPeriods = false;
    // dateAxis.dateFormats.setKey("hour", "hh:mm a");  // Format for hour interval
    // dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a"); // Prevent displaying dates
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineY.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min =1000000;
    //  valueAxis.max =1150000
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.title.text = 'Gas Consumption (CCF)';
    valueAxis.title.fill = am4core.color('#FF8714');
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fontSize = fontSize;
    valueAxis.renderer.minGridDistance = minGridDistance;
    valueAxis.renderer.labels.template.fill = am4core.color('#FF8714');
    valueAxis.renderer.grid.template.stroke = am4core.color('#fff');
    valueAxis.cursorTooltipEnabled = false;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.yAxis = valueAxis;
    series.dataFields.valueY = 'gas_meter_reading';

    series.dataFields.dateX = 'record_time';
    series.name = 'Gas Consumption';
    series.stroke = am4core.color('#FF8714');
    series.fill = am4core.color('#FF8714');
    series.legendSettings.labelText = "{name}";
    series.tooltipText = '[font-size: 12px]{name}: {valueY} (CCF)[/]';
    series.strokeWidth = 2;
    series.tensionX = 1;

    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#FF8714');
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 0;
    circle.stroke = am4core.color('#FF8714');
    circle.strokeWidth = 2;

    chart.legend.position = 'bottom';
    var title = chart.titles.create();
    title.fill = am4core.color('#fff');

  }

  closed() {
    this.dialogRef1.close();
  }

  drawProductionChart() {

    am4core.useTheme(am4themes_animated);
    // Create chart instance
    const fontSize = this.getResponsiveFontSize();
    const minGridDistance = this.getResponsiveminGridDistance();
    const chartHeight = this.getResponsiveminChartHeight();
    var chart = am4core.create('chartProduction', am4charts.XYChart);
    console.log('fontSize', fontSize);
    chart.logo.disabled = true;
    chart.responsive.enabled = true;
    chart.paddingRight = 15;
    chart.marginRight = 15;
    chart.paddingLeft = 10;
    chart.marginLeft = 10;
    var chartData = this.KPI2;
    chart.data = chartData;
    chart.height = chartHeight;
    chart.width = am4core.percent(98);
    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';
    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    let date = new Date();
    const myDateValue = new Date(this.myDateValue);
    const normalizedMyDateValue = new Date(myDateValue.setHours(0, 0, 0, 0));
    const normalizedCurrentDate = new Date(date.setHours(0, 0, 0, 0));

    if (normalizedMyDateValue.getTime() === normalizedCurrentDate.getTime()) {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
    } else {
      dateAxis.min = new Date(this.myDateValue).setHours(0, 0, 0, 0);
      dateAxis.max = new Date(this.myDateValue).setHours(23, 59, 59, 999);
    }
    dateAxis.renderer.grid.template.location = 0;
    //   dateAxis.renderer.minGridDistance = 20;
    // dateAxis.renderer.grid.template.stroke = am4core.color('#000');
    dateAxis.renderer.grid.template.stroke = am4core.color('#fff');

    // dateAxis.title.fill = '#fff';
    dateAxis.title.fill = am4core.color('#fff');
    // dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.renderer.labels.template.fontSize = fontSize;
    // dateAxis.renderer.labels.template.fill = am4core.color('#000');
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.title.text = 'Time';
    dateAxis.renderer.labels.template.fontSize = fontSize;
    dateAxis.baseInterval = {
      timeUnit: 'hour',
      count: 1,
    };
    dateAxis.skipEmptyPeriods = false;
    // dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH-mm';
    dateAxis.dateFormats.setKey("hour", "hh:mm a");  // Format for hour interval
    dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a"); // Prevent displaying dates
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineY.disabled = true;
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if(chart.data && chart.data[0] && chart.data[0].mix_tons_run === null){
      valueAxis.max = 100;
    }
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.title.text = 'Production (Tons)';
    // valueAxis.title.fill = am4core.color('#333');
    valueAxis.title.fill = am4core.color('#fff');
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fontSize = fontSize;
    valueAxis.renderer.minGridDistance = minGridDistance;
    // valueAxis.renderer.labels.template.fill = am4core.color('#333');
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');
    valueAxis.renderer.grid.template.stroke = am4core.color('#fff');
    valueAxis.renderer.minGridDistance = minGridDistance;
    valueAxis.cursorTooltipEnabled = false;
    var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.min = 0;
    valueAxis1.renderer.inside = false;
    valueAxis1.renderer.labels.template.disabled = true;
    //   valueAxis1.title.text = 'actual';
    // valueAxis1.title.fill = am4core.color('#333');
    valueAxis1.title.fill = am4core.color('#fff');
    // valueAxis1.title.fontWeight = 'bold';
    valueAxis1.renderer.labels.template.fontSize = fontSize;
    valueAxis1.renderer.minGridDistance = minGridDistance;
    // valueAxis1.renderer.labels.template.fill = am4core.color('#333');
    valueAxis1.renderer.labels.template.fill = am4core.color('#fff');
    valueAxis1.renderer.grid.template.stroke = am4core.color('#fff');
    valueAxis1.renderer.minGridDistance = minGridDistance;
    valueAxis1.cursorTooltipEnabled = false;
    valueAxis1.syncWithAxis = valueAxis;
    valueAxis1.renderer.opposite = false;
    valueAxis1.max = valueAxis.max;
    // Determine which emission type is selected and create series accordingly
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'mix_tons_run';
    series.dataFields.dateX = 'record_time_epoch';
    series.name = 'Actual';
    series.columns.template.width = am4core.percent(30);
    series.columns.template.propertyFields.url = "url";
    series.strokeWidth = 3;
    series.stroke = am4core.color('#FF8714');
    series.fill = am4core.color('#FF8714');
    // series.legendSettings.labelText =  ' [ font-size:15px ]{name}[/]'
    series.tooltipText = '[font-size: 12px]{name}: {valueY}[/]';
    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = 'optimized_mix_tons_run';
    series1.yAxis = valueAxis;
    series1.dataFields.dateX = 'record_time_epoch';
    series1.name = 'Estimated Production';
    series1.columns.template.width = am4core.percent(30);
    series1.columns.template.propertyFields.url = "url";
    series1.strokeWidth = 3;
    series1.stroke = am4core.color('#00b050');
    series1.fill = am4core.color('#00b050');
    // series1.legendSettings.labelText =  ' [ font-size:12px ]{name}[/]'
    series1.tooltipText = '[font-size: 12px]{name}: {valueY}[/]';
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#FF8714'); // tooltips grab fill from parent by default
    let circle = bullet.createChild(am4core.Circle);
    // circle.radius = 2;
    // circle.strokeWidth = 2;
    let bullet1 = series1.bullets.push(new am4charts.Bullet());
    bullet1.fill = am4core.color('#00b050'); // tooltips grab fill from parent by default
    let circle1 = bullet1.createChild(am4core.Circle);
    // circle1.radius = 2;
    // circle1.strokeWidth = 2;
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.legend.labels.template.fill = am4core.color('#fff');
    var title = chart.titles.create();
    title.fill = am4core.color('#333');
  }

  getResponsiveFontSize() {
    const screenWidth = window.innerWidth;

    // Set different font sizes based on screen width
    if (screenWidth > 1200) {
      return 15; // Large screens
    } else if (screenWidth > 768) {
      return 12; // Medium screens
    } else {
      return 10; // Small screens
    }
  }
  getResponsiveminGridDistance() {
    const screenWidth = window.innerWidth;

    // Set different font sizes based on screen width
    if (screenWidth > 1200) {
      return 40; // Large screens
    } else if (screenWidth > 768) {
      return 30; // Medium screens
    } else {
      return 20; // Small screens
    }
  }
  getResponsiveminChartHeight() {
    const screenWidth = window.innerWidth;

    // Set different font sizes based on screen width
    if (screenWidth > 1200) {
      return 400; // Large screens
    } else if (screenWidth > 768) {
      return 350; // Medium screens
    } else {
      return 300; // Small screens
    }
  }
}