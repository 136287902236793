import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const baseURL = environment["baseUrl"];

@Injectable({
  providedIn: "root",
})
export class ApiCallsService {
  public headerTokens: any;
  constructor(private http: HttpClient) {
    const token: string = localStorage.getItem("user");
    const cToken = JSON.parse(token)["token"];
    const sToken = cToken.substr(2, cToken.length - 3);
    this.headerTokens = {
      headers: new HttpHeaders()
        .set("Authorization", `Bearer ${sToken}`)
        .set(
          "Content-type",
          "application/x-www-form-urlencoded; charset=ISO-8859-1"
        ),
    };
  }

  MIxidlist(sDate: any, eDate: any, hplant: any): Observable<any> {
    return this.http
      .get(
        `${baseURL}history/production/dashboard/mix?start_date=${sDate}&end_date=${eDate}&type=min&plant_type=${hplant}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }
  // RealTime GuageAPI'S
  LiveUnifiedHMIData(hplant: any): Observable<any> {
    return this.http
      .get(
        `${baseURL}live/unified/data?plant_type=${hplant}&name=1,2,3,4,5,6,7,8`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  // Plant Performance Analysis Api's
  HistoryProdData(
    sDate: any,
    eDate: any,
    hmix: any,
    hplant: any,
    hjob: any,
    htype: any
  ): Observable<any> {
    return this.http
      .get(
        `${baseURL}plant_performance_analysis?start_date=${sDate}&end_date=${eDate}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }
  HistoryProdDatahour(
    sDate: any,
    eDate: any,
    hmix: any,
    hplant: any,
    hjob: any,
    htype: any
  ): Observable<any> {
    return this.http
      .get(
        `${baseURL}history/production/unified/data?start_date=${sDate}&end_date=${eDate}&mix_id=${hmix}&plant_type=${hplant}&job_no=${hjob}&status=${htype}&table=hour`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }
  DownloadHMIall(sDate: any, eDate: any, hplant: any) {
    return this.http
      .get(
        `${baseURL}history/production/hmi/data?start_date=${sDate}&end_date=${eDate}&plant_type=${hplant}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  HMIplant_data(sDate: any, eDate: any, hplant: any) {
    return this.http
      .get(
        `${baseURL}history_plantdata?start_date=${sDate}&end_date=${eDate}`,
        // `${baseURL}history_plantdata`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }


  // Energy

  EnergyConsumptionTable(
    hplant: any): Observable<any> {
      return this.http
      .get(
        `${baseURL}energy/consumption/table?plant_type=${hplant}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  EnergyConsumptionGraph(
    sDate: any,
    eDate: any,
    hmix: any,
    hplant: any,
    hjob: any,
    htype: any
  ): Observable<any> {
    return this.http
      .get(
        `${baseURL}energy_analysis?start_date=${sDate}&end_date=${eDate}&status=${htype}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  // incidents Page
  TotalUnifieddata(
    sDate: any,
    eDate: any,
    hmix: any,
    hplant: any,
    hjob: any,
    htype: any
  ) {
    return this.http
      .get(
        `${baseURL}incident/unified/data?start_date=${sDate}&end_date=${eDate}&mix_id=${hmix}&plant_type=${hplant}&job_no=${hjob}&status=${htype}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  // Comparison Gn vs CMI
  ComparisonUnified(sDate: any, eDate: any) {
    return this.http
      .get(
        `${baseURL}compare/unified/data?start_date=${sDate}&end_date=${eDate}`,
        this.headerTokens
      )
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  // UserManagement Api's
  AddUser(body): Observable<any> {
    return this.http
      .post(`${baseURL}auth/add/user`, body, this.headerTokens)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  getAllUsers() {
    return this.http.get(`${baseURL}auth/get/user`, this.headerTokens).pipe(
      map(
        (response) => {
          return response;
        },
        (err) => {
          // // (err);
        }
      )
    );
  }

  UpdateUser(body): Observable<any> {
    return this.http
      .put(`${baseURL}auth/update/user`, body, this.headerTokens)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            console.log(err);
          }
        )
      );
  }

  DeleteUserId(userid: any) {
    return this.http
      .delete(`${baseURL}auth/delete/user?id=${userid}`, this.headerTokens)
      .pipe(
        map(
          (response) => {
            return response;
          },
          (err) => {
            // // (err);
          }
        )
      );
  }
    getPlantData(): Observable<any> {
        return this.http
            .get(
                `${baseURL}plantdata`,
                this.headerTokens
            )
            .pipe(
                map(
                    (response: any) => response,
                    (err) => {
                        console.log(err);
                    }
                )
            );
    }
    getPopupData(): Observable<any> {
        return this.http
            .get(
                `${baseURL}popup`,
                this.headerTokens
            )
            .pipe(
                map(
                    (response: any) => response,
                    (err) => {
                        console.log(err);
                    }
                )
            );
    }
    // Add the postChoice method
    postChoice(data: any): Observable<any> {
        return this.http.post<any>(
            `${baseURL}popup_choice`,
            data,  // Pass the data object directly
            this.headerTokens  // Include headers if needed
        ).pipe(
            map(
                (response: any) => response,
                (err) => {
                    console.error('Error:', err);
                    // Handle error as needed
                    throw err;
                }
            )
        );
    }
    postchat(data: any): Observable<any> {
        return this.http.post<any>(
            `${baseURL}bot`,
            data,  // Pass the data object directly
            this.headerTokens  // Include headers if needed
        ).pipe(
            map(
                (response: any) => response,
                (err) => {
                    console.error('Error:', err);
                    // Handle error as needed
                    throw err;
                }
            )
        );
    }
}
