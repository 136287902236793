import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';

const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    selectedDateFilter: any;
    qrData: any;
    date: any;
    recordTime: any = new BehaviorSubject(null);
    constructor(readonly http: HttpClient) { }
    
   
    /* Getter for date filter in reports */
    get dateFilter(): any {
      return this.selectedDateFilter;
    }
  
    /* Setter for date filter in reports */
    set dateFilter(filter) {
      this.selectedDateFilter = filter;
    }
  
    get  record(): any {
      return this.recordTime.getValue();
    }
    set record(filter: any) {
      this.recordTime.next(filter);
    }
    /* Observable to track device filter changes */
    get record$(): Observable<any> {
      return this.recordTime.asObservable();
    }

    /* Getter for selected role in assessment form */
    get qrdata() {
      return this.qrData;
    }
  
    /* Getter for selected role in assessment form */
    set qrdata(data) {
      this.qrData = data;
    }

  
    formAmchartData(data, keys, parameter) {
  
      const dataSet = data;
  
      const timestamps = [];
  
  
      // // console.log(this.selectedDateFilter)
  
      /* Push all the timeinstance array from all the data */
  
      keys.sort().forEach(e => {
        timestamps.push(dataSet[e]['timeinstance']);
      });
  
      /* Merge all the dates */
      const mergedArray = [].concat.apply([], timestamps);
  
      /* After merging get the unique dates */
      const uniqueDates = [...new Set(mergedArray)];
  
      let selectedEndDate = new Date(this.selectedDateFilter['end_date']);
  
      let redableEndDate = `${selectedEndDate.getFullYear()}-${selectedEndDate.getMonth() + 1}-${selectedEndDate.getDate()}`;
  
      if(!uniqueDates.includes(redableEndDate)){
          uniqueDates.push(redableEndDate);
      }
      /* Sort the dates in ascending order */
      uniqueDates.sort((a: Date, b: Date) => {
  
        if (new Date(a).getTime() < new Date(b).getTime()) {
          return -1;
        }
        if (new Date(a).getTime() > new Date(b).getTime()) {
          return 1;
        }
        return 0;
      });
      
      /* Sort the dates in ascending order */
      uniqueDates.sort((a: Date, b: Date) => {
  
        if (new Date(a).getTime() < new Date(b).getTime()) {
          return -1;
        }
        if (new Date(a).getTime() > new Date(b).getTime()) {
          return 1;
        }
        return 0;
      });
      // console.log(this.selectedDateFilter,uniqueDates)
  
      /* Iterate the sorted dates to form required JSON */
      let d = uniqueDates
        .map((e, i) => Object.assign({},
          ...keys.map((k) => {
  
            const index = dataSet[k]['timeinstance'].findIndex(el => el === e);
  
            return ({
              [k]: index > -1 ? dataSet[k][parameter][index] : 0,
              date: e
            })
          })
        )
        );
      return d;
    }
  
    /* method which returns date filters, converting them to epoch time */
  
    formDateQuery(filters) {
      const start = new Date(new Date(filters['start_date']).setHours(0, 0, 0, 0)).getTime();
      const end = new Date(new Date(filters['end_date']).setHours(23, 59, 59, 999)).getTime();
  
      return `start_date=${start}&end_date=${end}`;
    }
  
  
  }



