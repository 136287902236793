import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenAuth } from '@app/core/auth/auth.interceptor';
import { AuthService } from '@app/core/auth/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTooltipModule,
    MatSnackBar,
    
} from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogchartsComponent } from './components/dialogcharts/dialogcharts.component';
import { LivealertsComponent } from './components/livealerts/livealerts.component';
import { HistoryDialogchartsComponent } from './components/history-dialogcharts/history-dialogcharts.component';
import { MoisturealertComponent } from '@app/components/moisturealert/moisturealert.component';
import { AlertComponent } from '@app/components/alert/alert.component';
import {Popup1Component} from '@app/components/popup1/popup1.component';






@NgModule({
  declarations: [
    AppComponent,
    DialogchartsComponent,
    LivealertsComponent,
    HistoryDialogchartsComponent,
    MoisturealertComponent,
    AlertComponent,
    Popup1Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSidenavModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatTooltipModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
   MatSnackBarModule ,
      MatDialogModule,
      BrowserModule, 
    FormsModule, 
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot() ,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogchartsComponent,
    LivealertsComponent,
    HistoryDialogchartsComponent,
      AlertComponent,
      Popup1Component,
  ],
})
export class AppModule {}
