import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-moisturealert',
  templateUrl: './moisturealert.component.html',
  styleUrls: ['./moisturealert.component.css']
})
export class MoisturealertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
