import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';

const baseURL1 = environment["baseUrl"];

@Injectable({
    providedIn: 'root'
})
export class ApiCallService2 {
    private headerTokens: HttpHeaders;

    constructor(private http: HttpClient) {
        this.setHeaders();
    }

    private setHeaders(): void {
        const token: string = localStorage.getItem("user");
        if (token) {
            const cToken = JSON.parse(token)["token"];
            const sToken = cToken.substr(2, cToken.length - 3);
            this.headerTokens = new HttpHeaders({
                "Authorization": `Bearer ${sToken}`,
                "Content-type": "application/x-www-form-urlencoded; charset=ISO-8859-1"
            });
        }
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(error);
    }

    getPlantData(): Observable<any> {
        return this.http.get(`${baseURL1}plantdata`, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    getImageData(): Observable<any> {
      return this.http.get(`${baseURL1}Image_data`, { headers: this.headerTokens }).pipe(
          map((response: any) => response),
          catchError(this.handleError)
      );
  }

    getGraphData(): Observable<any> {
        return this.http.get(`${baseURL1}moisture_analysis`, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    getGraphData1(): Observable<any> {
        return this.http.get(`${baseURL1}agg_temp_analysis`, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    getGraphData2(): Observable<any> {
        return this.http.get(`${baseURL1}production_analysis`, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    getPopupData(): Observable<any> {
        return this.http.get(`${baseURL1}popup`, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    postChoice(data: any): Observable<any> {
        return this.http.post<any>(`${baseURL1}popup_choice`, data, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }

    logout_post(): Observable<any> {
      return this.http.post<any>(`${baseURL1}logout_page`,{},{ headers: this.headerTokens }).pipe(
          map((response: any) => response),
          catchError(this.handleError)
      );
  }

    postchat(data: any): Observable<any> {
        return this.http.post<any>(`${baseURL1}bot`, data, { headers: this.headerTokens }).pipe(
            map((response: any) => response),
            catchError(this.handleError)
        );
    }
    MIxidlist(sDate: any, eDate: any, hplant: any): Observable<any> {
        return this.http
          .get(
            `${baseURL1}history/production/dashboard/mix?start_date=${sDate}&end_date=${eDate}&type=min&plant_type=${hplant}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
      // RealTime GuageAPI'S
      LiveUnifiedHMIData(hplant: any): Observable<any> {
        return this.http
          .get(
            `${baseURL1}live/unified/data?plant_type=${hplant}&name=1,2,3,4,5,6,7,8`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }


      // KPI Analysis 
      historyPlantData(
        sDate: any,
        eDate: any,
        type: any,
      ): Observable<any> {
        return this.http
          .get(
 
            `${baseURL1}kpi_data_analysis?start_date=${sDate}&end_date=${eDate}&type=${type}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }

      historyPlantDatahour(
        sDate: any,
        eDate: any,
      ): Observable<any> {
        return this.http
          .get(
 
            `${baseURL1}kpi_data_analysis?start_date=${sDate}&end_date=${eDate}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }

      historyPlantDataDay(
        sDate: any,
        eDate: any,
      ): Observable<any> {
        return this.http
          .get(
 
            `${baseURL1}kpi_data_analysis?start_date=${sDate}&end_date=${eDate}&period=days`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
      MixIdPlantDataDay(
        sDate: any,
        eDate: any,
      ): Observable<any> {
        return this.http
          .get(
 
            `${baseURL1}mix_id_analysis?start_date=${sDate}&end_date=${eDate}&period=days`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }

      MixIdPlantDataDay_ml(
        sDate: any,
        eDate: any,
      ): Observable<any> {
        return this.http
          .get(
 
            `${baseURL1}mix_id_analysis_model?start_date=${sDate}&end_date=${eDate}&period=days`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }


      

    
      // Plant Performance Analysis Api's
      HistoryProdData(
        sDate: any,
        eDate: any,
        hmix: any,
        hplant: any,
        hjob: any,
        htype: any
      ): Observable<any> {
        return this.http
          .get(
            `${baseURL1}history/production/unified/data?start_date=${sDate}&end_date=${eDate}&mix_id=${hmix}&plant_type=${hplant}&job_no=${hjob}&status=${htype}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
      HistoryProdDatahour(
        sDate: any,
        eDate: any,
        hmix: any,
        hplant: any,
        hjob: any,
        htype: any
      ): Observable<any> {
        return this.http
          .get(
            `${baseURL1}history/production/unified/data?start_date=${sDate}&end_date=${eDate}&mix_id=${hmix}&plant_type=${hplant}&job_no=${hjob}&status=${htype}&table=hour`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }

      DownloadHMIall(sDate: any, eDate: any, hplant: any) {
        return this.http
          .get(
            `${baseURL1}history/production/hmi/data?start_date=${sDate}&end_date=${eDate}&plant_type=${hplant}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      HMIplant_data(sDate: any, eDate: any, hplant: any,mix_id: any,prod: any) {
        return this.http
          .get(
            `${baseURL1}download_plantdata?start_date=${sDate}&end_date=${eDate}&mix_id=${mix_id}&prod_status=${prod}`,
            // `${baseURL}history_plantdata`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }

      Binplant_data(sDate: any, eDate: any, hplant: any,mix_id: any,prod: any) {
        return this.http
          .get(
            `${baseURL1}bin_plantdata?start_date=${sDate}&end_date=${eDate}&mix_id=${mix_id}&prod_status=${prod}`,
            // `${baseURL}history_plantdata`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }

      Download_Report(sDate: any, eDate: any) {
        return this.http
          .get(
            `${baseURL1}daily_data?start_date=${sDate}&end_date=${eDate}`,
            // `${baseURL}history_plantdata`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }


      HMIplant_data_new(sDate: any, eDate: any, hplant: any,mix_id: any) {
        return this.http
          .get(
            `${baseURL1}daily_report_analysis?start_date=${sDate}&end_date=${eDate}&mix_id=${mix_id}`,
            // `${baseURL}history_plantdata`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
    
      // Energy
    
      EnergyConsumptionTable(
        hplant: any): Observable<any> {
          return this.http
          .get(
            `${baseURL1}energy/consumption/table?plant_type=${hplant}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      EnergyConsumptionGraph(
        sDate: any,
        eDate: any,
        hmix: any,
        hplant: any,
        hjob: any,
        htype: any
      ): Observable<any> {
        return this.http
          .get(
            `${baseURL1}energy/unified/data?start_date=${sDate}&end_date=${eDate}&mix_id=${hmix}&plant_type=${hplant}&job_no=${hjob}&status=${htype}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      // incidents Page
      TotalUnifieddata(
        sDate: any,
        eDate: any,
        hmix: any,
        hplant: any,
        hjob: any,
        htype: any
      ) {
        return this.http
          .get(
            `${baseURL1}incident/unified/data?start_date=${sDate}&end_date=${eDate}&mix_id=${hmix}&plant_type=${hplant}&job_no=${hjob}&status=${htype}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      // Comparison Gn vs CMI
      ComparisonUnified(sDate: any, eDate: any) {
        return this.http
          .get(
            `${baseURL1}compare/unified/data?start_date=${sDate}&end_date=${eDate}`,
            { headers: this.headerTokens }
          )
          .pipe(
            map(
              (response: any) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      // UserManagement Api's
      AddUser(body): Observable<any> {
        return this.http
          .post(`${baseURL1}auth/add/user`, body, { headers: this.headerTokens })
          .pipe(
            map(
              (response) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      getAllUsers() {
        return this.http.get(`${baseURL1}auth/get/user`, { headers: this.headerTokens }).pipe(
          map(
            (response) => {
              return response;
            },
            (err) => {
              // // (err);
            }
          )
        );
      }
    
      UpdateUser(body): Observable<any> {
        return this.http
          .put(`${baseURL1}auth/update/user`, body, { headers: this.headerTokens })
          .pipe(
            map(
              (response) => {
                return response;
              },
              (err) => {
                console.log(err);
              }
            )
          );
      }
    
      DeleteUserId(userid: any) {
        return this.http
          .delete(`${baseURL1}auth/delete/user?id=${userid}`, { headers: this.headerTokens })
          .pipe(
            map(
              (response) => {
                return response;
              },
              (err) => {
                // // (err);
              }
            )
          );
      }
    
}
