import { Component, OnInit, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ApiCallsService } from "@app/routes/dashboard/pages/dashboard/api-call-service";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

const imgURL = "/fileserverimg/";
const baseUrl2 = "/fileserverimg/";
const baseUrl3 = "https://fileserver.asphalt4.0.livnsense.com/";
@Component({
  selector: "app-dialogcharts",
  templateUrl: "./dialogcharts.component.html",
  styleUrls: ["./dialogcharts.component.css"],
})
export class DialogchartsComponent implements OnInit {
  // @Input() graphDataMixoutcome:any[];
  // mixRealgraphData: any = [];
  Graph1: any[];
  Graph2: any[];
  Graph3: any[];
  Graph4: any[];
  Graph5: any[];
  selectedContainer = "1";
  imageURL: any;
  imageURL1: any;
  Selectcontainer = [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
  ];
  appliedDateFilters: any = {};
  LiveUnionAlertData: any;
  HMIimagedata: any;
  selectedMIXID = "DASB198960";
  selectedMixID: any = [];
  selectedJOB = "09-023";
  selectedStatus = "P','NP";
  // selectedPlant = "TCII";
  DefaultDt = "3/06/2023";
  DescpData: any;
  imgurl: any;
  ImageBinary: string;
  LastTime: any;
  HMIIMageScreen: any;
  token = localStorage.getItem("user");
  selectedMIX: any;
  selectedPlanttype: any;
  selectDPlantType: any;
  Plant: any;
  isLoading: boolean = true;
  LiveGasAlertData: any;
  constructor(
    readonly apiCallsService: ApiCallsService,
    readonly sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogchartsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // console.log(this.data.graphDataMixoutcome)
    if (this.data.clicked == 1) {
      this.getLiveunionAlert();
    } else if (this.data.clicked == 2) {
      this.isLoading = true;
      this.Plant = this.data.Planttype;
      this.HMIIMageScreen = this.data.Filename;
      setTimeout(() => {
        this.isLoading = false;
        },2000);
      // console.log(this.Plant, this.data.Planttype,  this.HMIIMageScreen)
   
    } else if (this.data.clicked == 3){
      this.LiveGasAlertData = this.data.graphgasDataAlerts;
    }
  }
  getLiveunionAlert() {
    this.LiveUnionAlertData = this.data.graphDataAlerts;
  }




  closed() {
    this.dialogRef.close();
  }

  // openDialog5() {
  //   const dialogRef = this.dialog.open(DialogchartsComponent, {
  //     width: "600px",
  //     height: "400px",
  //     data: { DescriptionData: this.DescpData },
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
}
