import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {MatSnackBar} from '@angular/material';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  private inactiveTimeout: any;

  constructor(private router: Router, public snackBar: MatSnackBar) {}

  @HostListener("document:visibilitychange", ["$event"])
  onVisibilityChange(event: Event): void {
    if (document.hidden) {
      this.startInactiveTimeout();
    } else {
      this.clearInactiveTimeout();
    }
  }

  private startInactiveTimeout(): void {
    this.inactiveTimeout = setTimeout(() => {
      window.location.reload();
    }, 
    2 * 60 * 60 * 1000  // 2 hours in milliseconds
      //  5 * 60 * 1000 // 5 minutes in milliseconds
    );
  }

  private clearInactiveTimeout(): void {
    if (this.inactiveTimeout) {
      clearTimeout(this.inactiveTimeout);
    }
  }

  private setupTabActivityDetection(): void {
    // Initial setup for visibility change
    if (document.hidden) {
      this.startInactiveTimeout();
    }
  }

  ngOnInit(): void {
    this.setupTabActivityDetection();
    const token = localStorage.getItem("user");
    if (token) {
      const tokenPayload = token.split(".")[1];
      const decodedPayload = atob(tokenPayload);
      const decodedPayloadJson = JSON.parse(decodedPayload);
      const expirationTime = decodedPayloadJson.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      console.log(
        "Current Time:",
        new Date(currentTime * 1000).toLocaleString()
      );
      console.log(
        "Expiration Time:",
        new Date(expirationTime * 1000).toLocaleString()
      );
      if (currentTime >= expirationTime) {
        console.log("Token has expired.");
        this.logout();
        setTimeout(() => {
          this.openSnackBar();
        }, 100);
      } else {
        console.log("Token is still valid.");
      }
    }
  }

  ngOnDestroy() {
    this.clearInactiveTimeout();
  }

  openSnackBar() {
    this.snackBar.open('Session Timeout', '',{
      duration: 2000,
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["auth"]);
  }
}
